// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyCdVT1GWJNbwHXL9yDLhAvKNznLWfIF7Dg",
    authDomain: "productionqc-8f6f2.firebaseapp.com",
    databaseURL: "https://productionqc-8f6f2.firebaseio.com",
    projectId: "productionqc-8f6f2",
    storageBucket: "productionqc-8f6f2.appspot.com",
    messagingSenderId: "772812037246",
    appId: "1:772812037246:web:fbf8a3c71a5168a3cdcc2f",
    measurementId: "G-37JKFE0QFZ"
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
