import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';


import { Observable, of } from 'rxjs';
import firebase from 'firebase/app';

//import * as admin from 'firebase-admin';
import 'firebase/storage';
import "firebase/auth";
import "firebase/firestore";


export class DeviceUpdateEvent {
  data: any;
  changeType: string;
}


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*,Origin, Content-Type, X-Auth-Token,AUTHORIZATION',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
  })
};

@Injectable({
  providedIn: 'root'
})

export class HttpService {


  db = firebase.firestore();
  st = firebase.storage();
  

  serviceAccount = require("productionqc-8f6f2-firebase-adminsdk-18hte-6e738113b3.json");

  onDeviceDataChange: EventEmitter<DeviceUpdateEvent> = new EventEmitter<DeviceUpdateEvent>();



  constructor(private _http: HttpClient, private afs: AngularFirestore, public afAuth: AngularFireAuth) {

    this.afAuth.signInWithEmailAndPassword("beni@mes-ltd.co.il", "123456")


    this.db.collection("YO2Devices").onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {

        this.onDeviceDataChange.emit({ data: change.doc.data(), changeType: change.type });

      });

    });




  }



  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================
  public getCollectionList(collectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        console.log('success')
      })
    })
  }



  public getCollectionData(collectionpath, docid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(docid).get().subscribe((doc) => {
        if (doc.exists) {
          res({ id: doc.id, data: doc.data() });
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }

  public addCollectionItam(collectionpath, data) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).add(data)
        .then((doc) => {
          console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });


  }

  public updateCollectionData(collectionpath, Itemid, itemData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).set(itemData, { merge: true })
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).delete()
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error deleting document: ", error);
          rej(error);
        });
    });

  }

public deleteFile(imageName, path:string = 'Images/') {
  
  return new Promise<any>((res, rej) => {
    this.st.ref(path+imageName).delete().then( () => {
    
      console.log("file was deleted")
    })    
    .catch((err) => {    
      console.log("error ",err)
    })    

  })
  
  console.log("file to delete",path+imageName)
  //
}

  public getTotalNumber(collection) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collection).get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });

  }


  public getFileUrl(fileName) {
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      ref.getDownloadURL().then((url) => {
        res(url);
      }).catch(err => {
        rej(err);
      });
    });
  }


  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================


  public getYODevices() {
    let url = "https://us-central1-productionqc-8f6f2.cloudfunctions.net/getAllYO2Device";
    return this._http.get(url);
  }

  public getFilteredYODevices(testfield, pvalue) {
    let url = "https://us-central1-productionqc-8f6f2.cloudfunctions.net/getFilteredYO2Device?testfield=" + testfield + "&pvalue=" + pvalue;
    return this._http.get(url);
  }

  public updateYODevice(data) {

    let url = "https://us-central1-productionqc-8f6f2.cloudfunctions.net/updateYO2Device";

    return this._http.post(url, data)
      .subscribe(
        res => {
          console.log("Data=", data, "  Results: ", res);
        },
        err => {
          console.log("Error occured");
        }
      )

  }




  public  uploadfile(Imagefile) {

    let url = "https://us-central1-productionqc-8f6f2.cloudfunctions.net/uploadFile/";

    return this._http.post(url, Imagefile);
    /*
      .subscribe(
        res => {
          console.log("  Results: completed", res);
          
        },
        err => {
          console.log("Error occured");
          
        }
      )
*/

  }



}


