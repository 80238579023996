<div>
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
        <!-- Menu -->
        <div class="menu">
            <ul class="list"
                [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
                [perfectScrollbar]>
                <li class="sidebar-user-panel">
                    <div class="user-panel">
                        <div class=" image">
                            <img src="assets/images/usrbig.jpg" class="img-circle user-img-circle" alt="User Image" />
                        </div>
                    </div>
                    <div class="profile-usertitle">
                        <div class="sidebar-userpic-name font-weight-bold">Production QC</div>
                        <div class="profile-usertitle-job ">MES</div>
                    </div>
                </li>
                <!-- First level menu -->
                <li [class.active]="showMenu === sidebarItem.title" *ngFor="let sidebarItem of sidebarItems"
                    [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
                    <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
                    <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
                        *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]"
                        (click)="callMenuToggle($event, sidebarItem.title)">
                        <i [ngClass]="[sidebarItem.icon]"></i>
                        <span class="hide-menu">{{sidebarItem.title}}
                        </span>
                    </a>
                    <!-- Second level menu -->
                    <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                        <li *ngFor="let sidebarSubItem of sidebarItem.submenu"
                            [class.activeSub]="showSubMenu === sidebarSubItem.title"
                            [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                            <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                                (click)="callSubMenuToggle(sidebarSubItem.title)" [ngClass]="[sidebarSubItem.class]">
                                {{sidebarSubItem.title}}
                            </a>
                            <!-- Third level menu -->
                            <ul class="collapse" *ngIf="sidebarSubItem.submenu.length > 0"
                                [ngClass]="{'in' : showSubMenu === sidebarSubItem.title }">
                                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                                    [ngClass]="[sidebarSubsubItem.class]">
                                    <a [routerLink]="[sidebarSubsubItem.path]">
                                        {{sidebarSubsubItem.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- #Menu -->
    </aside>
    <!-- #END# Left Sidebar -->
</div>